import React from 'react';
import Map from './Map'


class MyLocation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date()
        }
    }

    componentDidMount() {
        setInterval(() => {
            this.setState({
                date: new Date()
            })
        }, 1000);
    }


    render() {
        return (
            <div>
                <div>{this.state.date.toLocaleTimeString()}</div>
                <Map app_id="UsJnxBdZmU3OLKsxfcXq" app_code="NtCbe4TLs_ATwjEzXW48Mg" />
            </div>
        );
    }
}

export default MyLocation;