import React from 'react'
export default class Map extends React.Component {

    // For conciseness simply included all parameters in the querystring directly

    constructor(props) {
        super(props);
        this.state = {
            url: 'https://image.maps.api.here.com/mia/1.6/mapview?w=600&h=300&z=18&t=5&poitxs=16&poitxc=black&poifc=yellow',
            points: [],
            point: ""
        }
    }
    componentDidMount() {
        setInterval(() => { this.getLocation() }, 1000);
    }

    getLocation = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({ point: position.coords.latitude + "," + position.coords.longitude });
                console.log(this.state.point);
            });
        }

        else {
            console.info("geolocation is not supported in this environment");
        }
    };

    // Helper function to format list of points

    getPOIList() {
        if (this.state.points.length > 0) {
            let param = '&poi=';
            for (var poi in this.state.points) {
                param += poi.latitude + ',' + poi.longitude;
            }
            return param;
        }

        return '';
    }

    // Render method builds the URL dynamically to fetch the image from the
    // HERE Map Image API

    render() {
        return (
            <div>
                <div>{this.state.points.length}</div>
                <img
                    src={this.state.url
                        + '&app_id=' + this.props.app_id
                        + '&app_code=' + this.props.app_code
                        + '&poi='+this.state.point
                    }
                    alt="Todo Map" />
            </div>

        );
    }
}